
export const chains = [
    {
        id: '0x1',
        token: 'ETH',
        label: 'Ethereum',
        rpcUrl: process.env.ETH_RPC || `https://rpc.ankr.com/eth`
    },
    {
        id: '0xa',
        token: 'ETH',
        label: 'Optimism',
        rpcUrl: process.env.OP_RPC || `https://rpc.ankr.com/optimism`
    },
    {
        id: '0xa4b1',
        token: 'ETH',
        label: 'Arbitrum',
        rpcUrl: process.env.ARB_RPC || `https://rpc.ankr.com/arbitrum`
    },
    {
        id: '0x2105',
        token: 'ETH',
        label: 'Base',
        rpcUrl: process.env.BASE_RPC || `https://rpc.ankr.com/base`
    },
    {
        id: '0x89',
        token: 'MATIC',
        label: 'Polygon',
        rpcUrl: process.env.POLYGON_RPC || `https://rpc.ankr.com/polygon`
    },
    {
        id: '0x38',
        token: 'BNB',
        label: 'BSC',
        rpcUrl: process.env.BSC_RPC || `https://rpc.ankr.com/bsc`
    },
    {
        id: '0xfa',
        token: 'FTM',
        label: 'Fantom',
        rpcUrl: process.env.FANTOM_RPC || `https://rpc.ankr.com/fantom`
    },
    {
        id: '0xa86a',
        token: 'AVAX',
        label: 'Avalanche',
        rpcUrl: process.env.AVAX_RPC || `https://rpc.ankr.com/avalanche`
    },
    {
        id: '0x19',
        token: 'CRO',
        label: 'Cronos',
        rpcUrl: process.env.CRONOS_RPC || `https://cronos-evm.publicnode.com`
    },
    {
        id: '0x504',
        token: 'GLMR',
        label: 'Moonbeam',
        rpcUrl: process.env.MOONBEAM_RPC || `https://rpc.ankr.com/moonbeam`
    },
    {
        id: '0x8ae',
        token: 'KAVA',
        label: 'Kava',
        rpcUrl: process.env.KAVA_RPC || `https://evm2.kava.io`
    },
    {
        id: '0x1e14',
        token: 'CANTO',
        label: 'Canto',
        rpcUrl: process.env.CANTO_RPC || `https://canto.slingshot.finance`
    },
    {
        id: '0x44d',
        token: 'ETH',
        label: 'zkEVM',
        rpcUrl: process.env.ZKEVM_RPC || `https://zkevm-rpc.com`
    },
];

export default chains;