import AllowanceTable from "../AllowanceTable/AllowanceTable";
import { useEffect, useState } from "react";
import "./Body.css";
import { useWallets } from "@web3-onboard/react";
import Data from '../../types/types';
import fetchAllowanceData from "../../utils/fetchAllowanceData";
import chains from "../../data/chains";
import chainsJson from "../../data/chains.json";
import jokes from "../../data/jokes.json";
import ERC20 from "../../abis/ERC20.json";
import { ethers, Signer } from "ethers";
import { WalletState } from "@web3-onboard/core";
import { useNotifications } from '@web3-onboard/react'

const defaultData: Data[] = [
    {
        token: '...',
        address: "0x...:)", 
        approved: "0x...;)",
        knownApprovedContract: '',
        allowance: 'Infinite'
    }
];

const findConnectedChain = (wallet: WalletState): string => {
    if (!wallet) return '';
    const chainId = wallet.chains[0].id;
    const chain = chains.find(chain => chain.id === chainId);
    return chain ? chain.label.toLowerCase() : '';
}

const getJoke = (): string => {
    const random = Math.floor(Math.random() * jokes.length);
    return jokes[random].joke;
};

const handleClick = async (data: Data[], wallet: WalletState, customNotification: any, setRefresh: React.Dispatch<React.SetStateAction<boolean>>) => {
    const provider = new ethers.BrowserProvider(wallet.provider);
    const signer: Signer = await provider.getSigner();
    
    let etherscanLink: string = '';
    chainsJson.forEach(chain => {
      if (chain.id === wallet.chains[0].id) etherscanLink = chain.explorer;
    });
  
    for (let i = 0; i < data.length; i++) {
        try {
            const contract = new ethers.Contract(data[i].address, ERC20, signer);
            let tx = await contract.approve(data[i].approved, 0);
            customNotification({
                message: "Your revoke is pending...",
                autoDismiss: 15000,
                type: 'pending'
              });
            tx = await tx.wait();

            customNotification({
                message: "You successfully revoked your allowance!",
                link: `${etherscanLink}tx/${tx.hash}`,
                autoDismiss: 15000,
                type: 'success'
            });
        } catch (error: any) {
            if (error.info.error.code === 4001) {
                customNotification({
                    message: 'User rejected transaction.',
                    autoDismiss: 15000,
                    type: 'error'
                });
            } else {
                customNotification({
                    message: 'This transaction failed.',
                    autoDismiss: 15000,
                    type: 'error'
                });
            }
           
            return;
        }
    }
  
    setRefresh(true);
}

const Body: React.FunctionComponent = () => {
    const wallet = useWallets()[0];
    const [data, setData] = useState(defaultData);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [
        , // the list of all notifications that update when notifications are added, updated or removed
        customNotification, // a function that takes a customNotification object and allows custom notifications to be shown to the user, returns an update and dismiss callback
        , // a function that takes a Notify object to allow updating of the properties
         // a function that takes a PreflightNotificationsOption to create preflight notifications
      ] = useNotifications();

    useEffect(() => {
        setLoading(true);
        if (wallet) {
            fetchAllowanceData(wallet, setData);
        }

        if (data !== defaultData) {
            setLoading(false);
        }

        setRefresh(false);
    }, [wallet, data, refresh]);

    const chainConnectedTo = (): string =>{
        if (wallet) return findConnectedChain(wallet);
        return '';
    }

    return (
        <div>
            <h1>Beefy Allowance Management Tool</h1>
            <h3>Manage your cross-chain token allowances</h3>
            <div id="revoke-all">
                { wallet && chainConnectedTo() !== ''  && data.length !== 0 ? <button onClick={() => handleClick(data, wallet, customNotification, setRefresh)}>Revoke All</button> : <></>}
            </div>
            <div className='body'>
                { 
                    loading ? <div id="loading">... Loading</div> :
                    wallet && chainConnectedTo() !== '' && data.length !== 0 ?
                    <AllowanceTable data={data} setRefresh={setRefresh} connectedChain={findConnectedChain(wallet)} /> 
                    : <div id="wait">
                       { wallet && chainConnectedTo() !== '' 
                       ? <h1 id="nothing">{`Nothing to revoke on this chain  ${String.fromCodePoint(0x1F604)}`}</h1>
                       : <div id="joke">
                            <h1>Connect Wallet.</h1>
                            <p>{getJoke()}</p>
                        </div>
                        }
                    </div>
                }
            </div>
            <div id="extra"></div>
        </div>
    )
}

export default Body;