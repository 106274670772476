import { Web3OnboardProvider, init } from '@web3-onboard/react';
import React from 'react';
import chains from '../src/data/chains';
import wallets from '../src/data/wallets';
import beefy from './images/beefy-primary.png';
import Header from './components/Header /Header';
import Body from './components/Body/Body';
import appIcon from './images/header-logo-notext.svg'
import './App.css';

const web3Onboard = init({
    wallets,
    chains,
    connect: {
      autoConnectLastWallet: true
    },
    appMetadata: {
      name: 'Beefy Allowance Tool',
      icon: appIcon,
      logo: beefy,
      description: 'A tool to manage your cross-chain token allowances.'
    },
    accountCenter: {
      hideTransactionProtectionBtn: true,
      desktop: {
        enabled: true,
        position: 'bottomRight'
      },
      mobile: {
        enabled: true,
        position: 'bottomRight'
      },
    },
    notify: {
        desktop: {
          enabled: true,
          transactionHandler: transaction => {
            console.log({ transaction })
          },
          position: 'bottomLeft'
        },
        mobile: {
          enabled: true,
          transactionHandler: transaction => {
            console.log({ transaction })
            if (transaction.eventCode === 'txPool') {
              return {
                type: 'success',
                message: 'You successfully sent the bridge transaction!',
              }
            }
          },
          position: 'topRight'
        }
      },
  })

function App() {
  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <div className="theme">
        <Header />
        <Body />
      </div>
    </Web3OnboardProvider>
  );
}

export default App;
