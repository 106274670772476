import { useConnectWallet } from '@web3-onboard/react';
import { WalletState } from '@web3-onboard/core';
//import { useSetChain } from '@web3-onboard/react'
import addressFixed from '../../utils/addressFixed';
import chains from '../../data/chains';
import './ConnectWallet.css';

const findConnectedChain = (wallet: WalletState): string => {
    const chainId = wallet.chains[0].id;
    const chain = chains.find(chain => chain.id === chainId);
    return chain ? chain.label : '';
}

function ConnectWallet () {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

    const chainConnectedTo = (): string =>{
         if (wallet) return findConnectedChain(wallet);
         return '';
    }

    return (
        <div id='module-header'>
            <div id='module-title'></div>
                <div id='network-box'>
                { wallet && chainConnectedTo() !== '' ? 
                    <button id='network-button'>
                        <img src={require(`../../images/chains/${findConnectedChain(wallet).toLowerCase()}.svg`)} alt={`${findConnectedChain(wallet)}`}></img>
                    </button> : <></> }
                </div> 
                <div>
                 <button id="connect-wallet" disabled={connecting} onClick={() => (wallet ? disconnect(wallet) : connect())}>
                    {connecting ? 'Connecting' : wallet ?  `${addressFixed(wallet.accounts[0].address)}` : 'Connect'} </button>
                    {wallet ? <div id="chain">{`Connected To: ${findConnectedChain(wallet)}`}</div> : <></>}
                </div>
        </div>
    )
}

export default ConnectWallet;