import fetch from 'node-fetch';
//import BigNumber from 'bignumber.js';
import Data from '../types/types';
import { WalletState } from '@web3-onboard/core';
import chains from '../data/chains.json';
//import { ethers } from 'ethers';
import { addressBookByChainId } from 'blockchain-addressbook';
import Chain from '../types/chain';
import knownAddresses from '../data/knownAddresses.json';
import isArray from 'lodash/isArray';

const approvalHash = "0x095ea7b3";
const unlimitedAllowance = "ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

const grabBeefyVaults = async (chainName: string) => {
    let vaults: object[] = [];
    try {
        fetch(`https://api.beefy.finance/vaults`).then((res) => res.json()).then((data) => {
            data.forEach((v: any) => {
                if (v.chain === chainName.toLowerCase()) {
                    vaults.push({
                        tokenName: v.earnedToken,
                        address: v.earnedTokenAddress
                    })
                }
            });
        });

        return vaults
    } catch (e) {
        console.error("Error fetching beefy vaults:", e);
    }

    return vaults;
    
}

export const fetchAllowanceData = async (wallet: WalletState, setData: any): Promise<Data[]> => {
  
    if (wallet) {
        const chainId = wallet.chains[0].id;
        const address = wallet.accounts[0].address;
        const findChain: Chain | undefined = chains.find((chain) => chain.chainId === chainId);
        const findChainName: string | undefined = chains.find((chain) => chain.chainId === chainId)?.name;

        if (!findChain) {
            // Handle the case when the chain is not found
            return [];
        }
        
        const id: string = findChain.id;
        const tokens = addressBookByChainId[id].tokens;
        
        let adjustedData: Data[] = [];
        let mappedData = new Map();
        try {
            const url = `${findChain.explorerApi}?module=account&action=txlist&address=${address}`;
            const response = await fetch(url);
            const data = await response.json();
        
            if (!data.result) {
                return [];
            }

            if (!isArray(data.result)) {
                console.log("error", data);
                return [];
            }

            const beefyVaults = await grabBeefyVaults(findChainName!);
            
            data.result.forEach((v: any) => {
                const approval = v.input.includes(approvalHash);
                if (approval && v.input) {
                    //const abiCoder = new ethers.AbiCoder();
                    //const inputSliced = v.input.slice(10);
                    //const decoded = abiCoder.decode(["address", "uint256"], "0x" + inputSliced);

                    const approved = "0x" + v.input.substring(34, 74);
                    const allowance = v.input.substring(74).toLowerCase() === unlimitedAllowance.toLowerCase() ? "Max" : "Less Than Max";

                    let tokenSymbol: string = "";
                    
                    Object.entries(tokens).forEach(([, value]) => {
                        if (value.address.toLowerCase() === v.to.toLowerCase()) {
                            tokenSymbol = value.symbol;
                        }
                    });

                    let knownContract = '';

            
                    Object.entries(beefyVaults).forEach(([, value]) => {
                        if (value.address.toLowerCase() === approved.toLowerCase()) {
                            knownContract = value.tokenName;
                        }

                        if (value.address.toLowerCase() === v.to.toLowerCase()) {
                            tokenSymbol = value.tokenName;
                        }
                    });

                
                    Object.entries(knownAddresses).forEach(([k,value]) => {
                        if (value.address.toLowerCase() === approved.toLowerCase()) {
                            knownContract = value.name;
                        }

                        if (value.address.toLowerCase() === v.to.toLowerCase()) {
                            tokenSymbol = value.name;
                        }
                    });

                    mappedData.set(v.to.toLowerCase().concat(approved), {
                        token: tokenSymbol,
                        address: v.to.toLowerCase(),
                        knownApprovedContract: knownContract,
                        approved: approved,
                        allowance: allowance,
                    });
                } 
            });

            for (const [, value] of mappedData.entries()) {
                if (value.allowance !== "0") {
                    adjustedData.push(value);
                }
            }
        } catch (e) {
            console.error("Error fetching allowance data:", e);
            setData(adjustedData);
            return adjustedData;
        }

            setData(adjustedData);
            return adjustedData;
        }
    
    return [];
};

export default fetchAllowanceData;
