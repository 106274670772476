import injectedModule from '@web3-onboard/injected-wallets';
//import coinbaseWalletModule from '@web3-onboard/coinbase';
import frameModule from '@web3-onboard/frame';

//import ledgerModule from '@web3-onboard/ledger';
//import walletConnectModule from '@web3-onboard/walletconnect'


export const wallets = [
    injectedModule(),
   //coinbaseWalletModule(),
    frameModule(),

    //ledgerModule(),
   // walletConnectModule()
];

export default wallets;