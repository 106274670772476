import ConnectWallet from "../ConnectWallet/ConnectWallet";
import './Header.css';

const Header: React.FunctionComponent = () => {
    return (
        <div id="header">
            <a href="https://beefy.com"><img id='logo' src={require("../../images/beefy.png")} alt="Beefy Logo" /></a>
            <ConnectWallet />
        </div>
    )
}

export default Header;