import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { useNotifications } from '@web3-onboard/react'
import { TableVirtuoso } from 'react-virtuoso';
import { useWallets } from '@web3-onboard/react';
import { WalletState } from '@web3-onboard/core';
import { ethers, Signer } from 'ethers';
import chains from '../../data/chains.json';
import addressFixed from '../../utils/addressFixed';
import ERC20 from '../../abis/ERC20.json';
import './AllowanceTable.css';
import Data from '../../types/types';

 
interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  height: number;
  width: number;
  fontWeight?: string
}

type AllowanceTableProps = {
  data: Data[];
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  connectedChain: string;
}

const columns: ColumnData[] = [
  {
    height: 10,
    width: 30,
    label: 'Token',
    dataKey: 'token',
    fontWeight: 'bold'
  },
  {
    height: 10,
    width: 75,
    label: 'Token Address',
    dataKey: 'address'
  },
  {
    height: 10,
    width: 75,
    label: 'Approved Contract',
    dataKey: 'approved'
  },
  {
    height: 10,
    width: 75,
    label: 'Allowance',
    dataKey: 'allowance',
  },
];


const handleClick = async (row: Data, wallet: WalletState, customNotification: any, setRefresh: any) => {
  const provider = new ethers.BrowserProvider(wallet.provider);
  const signer: Signer = await provider.getSigner();
  const contract = new ethers.Contract(row.address, ERC20, signer);
  
  let etherscanLink: string = '';
  chains.forEach(chain => {
    if (chain.id === wallet.chains[0].id) etherscanLink = chain.explorer;
  });

  try {
    let tx = await contract.approve(row.approved, 0);
  

    customNotification({
      message: "Your revoke is pending...",
      autoDismiss: 15000,
      type: 'pending'
    });

    await tx.wait();

    setRefresh(true);
    customNotification({
      message: "You successfully revoked your allowance.",
      link: `${etherscanLink}tx/${tx.hash}`,
      autoDismiss: 15000,
      type: 'success'
    });
  
  } catch (error: any) {
    console.error(error);
    if (error.info.error.code === 4001) {
      customNotification({
          message: 'User rejected transaction.',
          autoDismiss: 10000,
          type: 'error'
      });
  } else {
      customNotification({
          message: 'This transaction failed.',
          autoDismiss: 15000,
          type: 'error'
      });
  }
 
  return;
  }
}

const findImage = (token: string) => {
  if (token === '') return (
    <div></div>
  );

  try { 
    return (
      <img className='token-image' src={require(`../../images/tokens/single-assets/${token}.png`)} alt={`${token}`}></img>
    );
  } catch (e) {}

  try { 
    return (
      <img className='token-image'src={require(`../../images/tokens/single-assets/${token}.svg`)} alt={`${token}`}></img>
    );
  } catch (e) {}

  try { 
    return (
      <img className='token-image' src={require(`../../images/tokens/single-assets/${token}.jpg`)} alt={`${token}`}></img>
    );
  } catch (e) {}

  try { 
    return (
      <img className='token-image' src={require(`../../images/tokens/single-assets/${token}.webp`)} alt={`${token}`}></img>
    );
  } catch (e) {}
    
  return (
      <div>{token}</div>
    );
}

const VirtuosoTableComponents = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props: any) => (
    <Table {...props} sx={{ borderCollapse: 'seperate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent(matches: boolean) {
  return (
    <TableRow style={{ height: 20}}>
      {columns.map((column) => (
        matches ||
        column.dataKey !== 'allowance' ? 
        <TableCell
          key={`${column.dataKey}-${Math.random()}`}
          variant="head"
          align={'center'}
          style={matches ? { width: column.width, height: column.height, fontWeight: 'bolder', fontSize: 20, backgroundColor: 'white'} : { width: 35, height: 20, fontWeight: 'bold', fontSize: 13, backgroundColor: 'white'}}
          sx={{
            backgroundColor: 'white',
            borderBottom: '5px solid black',
            fontFamily: 'monospace'
          }}
        >{column.label}
        </TableCell> : <></>
      ))}
      <TableCell
        key={`revoke-${Math.random()}`}
        align='center'
        sx={{fontFamily: 'monospace'}}
        style={matches ? {width: 75, fontWeight: 'bolder', fontSize: 20 , backgroundColor: 'white', borderBottom: '5px solid black',} : {width: 60, fontWeight: 'bold', fontSize: 13 , backgroundColor: 'white', borderBottom: '5px solid black',}}>
        <div>Revoke Allowance</div>
      </TableCell>
    </TableRow>
  );
}

function rowContent(index: any, row: Data, wallet: WalletState, customNotification: any, setRefresh: React.Dispatch<React.SetStateAction<boolean>>, matches: boolean, etherscanLink: string) {
  return (
    <>
      {columns.map((column) => (
         matches || column.dataKey !== 'allowance' ?
        <TableCell
          key={`${column.dataKey}-${Math.random()}`}
          align={'center'}
          style={{fontWeight: 'bold', overflow: 'clip'}}
          sx={{fontFamily: 'monospace'}}
        > {
            column.dataKey === 'address' 
            ? <a href={`${etherscanLink}address/${row[column.dataKey]}`} target="_blank" rel="noopener noreferrer">{addressFixed(row[column.dataKey])}</a> 
            : column.dataKey === 'approved' && row['knownApprovedContract'] !== ''
            ? <a href={`${etherscanLink}address/${row[column.dataKey]}`} target="_blank" rel="noopener noreferrer">{row['knownApprovedContract']}</a> 
            : column.dataKey === 'approved' && row['knownApprovedContract'] === ''
            ? <a href={`${etherscanLink}address/${row[column.dataKey]}`} target="_blank" rel="noopener noreferrer">{addressFixed(row[column.dataKey])}</a> 
            : column.dataKey === 'token' 
            ? findImage(row[column.dataKey])
            : <span id='allowance-data'>{row[column.dataKey]}</span>
            }
        </TableCell> : <></>
      ))}
       <TableCell 
       key={`revoke-button-${Math.random()}`}
       width={100}
       align='center'
       sx={{fontFamily: 'monospace'}}
       >
          <button id="revoke-button" onClick={() => {handleClick(row, wallet, customNotification, setRefresh)}}>Revoke</button>
        </TableCell>
    </>
  );
}

const AllowanceTable: React.FunctionComponent<AllowanceTableProps> = ({data, setRefresh}) => {
  const wallet  = useWallets()[0];
  const matches = useMediaQuery('(min-width: 1100px)');

  let etherscanLink: string = '';
  chains.forEach(chain => {
    if (chain.chainId === wallet.chains[0].id) etherscanLink = chain.explorer;
  });

  const [
    , // the list of all notifications that update when notifications are added, updated or removed
    customNotification, // a function that takes a customNotification object and allows custom notifications to be shown to the user, returns an update and dismiss callback
    , // a function that takes a Notify object to allow updating of the properties
     // a function that takes a PreflightNotificationsOption to create preflight notifications
  ] = useNotifications();
  return (
    <div className='table-box'>
      <Paper style={matches ? { display: 'flex', margin: 'auto', width: '50%', height: '25vw' } : { display: 'flex', margin: 'auto', width: '85%', height: '90vw' }}>
        <TableVirtuoso
          style={{ zIndex: 0 }}
          data={data}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => fixedHeaderContent(matches)}
          itemContent={(index, row) => rowContent(index, row, wallet, customNotification, setRefresh, matches, etherscanLink)}
        />
      </Paper>
    </div>
  );
}

export default AllowanceTable;